// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

import jquery from 'jquery';
window.$ = window.jquery = window.jQuery = jquery;

require("bootstrap/dist/js/bootstrap.bundle.min");
require("@fullcalendar/core/main")
require("@fullcalendar/interaction/main");
require("@fullcalendar/daygrid/main");
require("@fullcalendar/timegrid/main");
require("@fullcalendar/list/main");
require("@fullcalendar/bootstrap/main");
require("@imanov/jquery.uniform/dist/js/jquery.uniform.standalone");
require("@nathanvda/cocoon");
require("@zeitiger/elevatezoom/jquery.elevatezoom");
require("intro.js/minified/intro.min");
require("driver.js/dist/driver.min")
require("jquery-timepicker/jquery.timepicker");
require("tablednd/dist/jquery.tablednd.min");
require("ahoy.js/dist/ahoy.min");
require("countup.js/dist/countUp.min");
require("jquery-validation");
require("bootstrap-datepicker/dist/js/bootstrap-datepicker");
require("copy-to-clipboard");

import driver from 'driver.js'
window.Driver = driver;

import introJs from 'intro.js'
window.introJs = introJs;

import copy from 'copy-to-clipboard'
window.copy = copy;

require("packs/table-order");
require("packs/plugins/chartjs/chart.min");
require("packs/plugins/d3/d3.min");
require("packs/plugins/nvd3/nv.d3.min");
require("packs/plugins/flot/jquery.flot.min");
require("packs/plugins/flot/jquery.flot.time.min");
require("packs/plugins/flot/jquery.flot.symbol.min");
require("packs/plugins/flot/jquery.flot.resize.min");
require("packs/plugins/flot/jquery.flot.tooltip.min");
require("packs/plugins/flot/jquery.flot.pie.min");
require("packs/plugins/datepicker/datepicker");
require("packs/plugins/slimscroll/jquery.slimscroll");
require("packs/plugins/easy-autocomplete/jquery.easy-autocomplete.min");
require("packs/plugins/wizard/wizard.min");
require("packs/plugins/confetti/confetti");

require("packs/fonts");
require("packs/conversion");
require("packs/calendar");
require("packs/main");
require("packs/tour");
require("packs/chef-charts");
require("packs/dashboard");
require("packs/autocomplete");
require("packs/text");
require("packs/searchable");
require("packs/onboarding");
require("packs/tracking");
require("packs/counter");
require("packs/shopping-list");
require("packs/delivery-map");
require("packs/timeline");
require("packs/genie");
require("packs/copy-text");
require("packs/chef-order");
require("packs/delivery");
require("packs/numbers-with-commas");
require("packs/home-screen");