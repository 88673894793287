$(document).on("turbolinks:load", function () {
    "use strict";

    const convert = require('convert-units');

    const unitMeasurementSelect = document.getElementById('ingredient_unit_measurement');
    if (typeof (unitMeasurementSelect) != 'undefined' && unitMeasurementSelect != null) {
        const volumePossibilities = convert().possibilities('volume');
        const massPossibilities = convert().possibilities('mass');

        volumePossibilities.concat(massPossibilities).forEach(p => {
            unitMeasurementSelect.options.add(new Option(longUnitMeasurementText(p), p));
        });
        unitMeasurementSelect.value = unitMeasurementSelect.dataset.selected
    }

    const longUnitMeasurementElements = document.getElementsByClassName('long-unit-measurement');
    for (let i = 0; i < longUnitMeasurementElements.length; i++) {
        const unitMeasurementDisplay = longUnitMeasurementElements[i];
        if (typeof (unitMeasurementDisplay) != 'undefined' && unitMeasurementDisplay != null) {
            unitMeasurementDisplay.innerHTML = longUnitMeasurementText(unitMeasurementDisplay.dataset.short);
        }
    }

    const ingredientMeasurementElements = document.getElementsByClassName('ingredient-measurement');
    for (let i = 0; i < ingredientMeasurementElements.length; i++) {
        const ingredientMeasurementElement = ingredientMeasurementElements[i];
        const possibilities = convert().from(ingredientMeasurementElement.dataset.unitMeasurement).possibilities();
        possibilities.forEach(p => {
            ingredientMeasurementElement.options.add(new Option(longUnitMeasurementText(p), p));
        });
        ingredientMeasurementElement.value = ingredientMeasurementElement.dataset.measurement;
    }

    const ingredientQuantityElements = document.getElementsByClassName('ingredient-quantity');
    for (let i = 0; i < ingredientQuantityElements.length; i++) {
        const ingredientQuantityElement = ingredientQuantityElements[i];
        ingredientQuantityElement.addEventListener('keyup', function (e) {
            const measurementField = document.getElementById(e.target.dataset.measurementField);

            const unitQuantity = parseInt(measurementField.dataset.unitQuantity);
            const unitMeasurement = measurementField.dataset.unitMeasurement;
            const unitPrice = parseFloat(measurementField.dataset.unitPrice);

            const quantity = parseInt(e.target.value);
            const measurement = measurementField.value;

            const divisor = convert(unitQuantity).from(unitMeasurement).to(measurement);
            const cost = (quantity * unitPrice) / divisor;

            const costTextField = document.getElementById(e.target.dataset.costField);
            costTextField.setAttribute('value', cost.toFixed(2));
        });
    }

    const materialQuantityElements = document.getElementsByClassName('material-quantity');
    for (let i = 0; i < materialQuantityElements.length; i++) {
        const materialQuantityElement = materialQuantityElements[i];
        materialQuantityElement.addEventListener('keyup', function (e) {
            const unitQuantity = parseInt(e.target.dataset.unitQuantity);
            const unitPrice = parseFloat(e.target.dataset.unitPrice);

            const quantity = parseInt(e.target.value);
            const costOfOne = unitPrice / unitQuantity;
            const cost = quantity * costOfOne;

            const costTextField = document.getElementById(e.target.dataset.costField);
            if (isNaN(cost))
                costTextField.setAttribute('value', '0.00');
            else
                costTextField.setAttribute('value', cost.toFixed(2));
        });
    }

    function longUnitMeasurementText(unitMeasurement) {
        return convert().describe(unitMeasurement).plural + ` (${unitMeasurement})`;
    }
});