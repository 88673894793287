import {CountUp} from 'countup.js';

$(document).on("turbolinks:load", function () {
    "use strict";

    const counterElements = $('.counter');
    counterElements.each(function () {
        const options = {
            duration: 0.5
        }
        const isCurrencyCounter = $(this).data('currency') === true
        let currencyOptions = {
            decimalPlaces: 2,
            prefix: '$'
        }
        currencyOptions = {...options, ...currencyOptions};
        const countUp = new CountUp($(this).attr('id'), $(this).data('number'), isCurrencyCounter ? currencyOptions : options);
        if (!countUp.error) {
            countUp.start();
        } else {
            console.error(countUp.error);
        }
    });
});
