$(document).on("turbolinks:load", function () {
    "use strict";

    const convert = require('convert-units');

    function units(quantity, measurement, unitMeasurement) {
        let convertedUnits = convert(quantity).from(measurement).to(unitMeasurement);
        if (convertedUnits < 1) {
            convertedUnits = 'less than 1 '
        } else {
            convertedUnits = `about ${Math.ceil(convertedUnits)}`;
        }

        return convertedUnits
    }

    if ($('#menu-item-shopping-list-view').length) {
        $('.menu-item-ingredient-row').each(function () {
            const unitField = $(this).find('.menu-item-ingredient-unit');
            if (unitField)
                unitField.html(units($(this).data('quantity'), $(this).data('measurement'), $(this).data('unit-measurement')));
        });
    }

    window.onMenuItemShoppingListClick = function() {
        const menuItemId = $('#menu-item-shopping-list-view').data('id');

        console.log(`printing shopping list for menu item ${menuItemId}`)

        let ingredients = []
        $('.menu-item-ingredient-row').each(function () {
            const ingredient = $(this);
            ingredients.push({
                recipe: ingredient.data('recipe'),
                name: ingredient.data('name'),
                quantity: `${ingredient.data('quantity')} ${ingredient.data('measurement')} (${units($(this).data('quantity'), $(this).data('measurement'), $(this).data('unit-measurement'))} ${ingredient.data('unit-measurement')})`,
            });
        });

        let materials = []
        $('.menu-item-material-row').each(function () {
            const material = $(this);
            materials.push({
                recipe: material.data('recipe'),
                name: material.data('name'),
                quantity: material.data('quantity')
            });
        });

        let form = $('<form></form>').attr('action', `/menu_items/${menuItemId}/print_shopping_list`).attr('method', 'post');
        form.append($("<input>").attr('type', 'hidden').attr('name', 'ingredients').attr('value', JSON.stringify(ingredients)));
        form.append($("<input>").attr('type', 'hidden').attr('name', 'materials').attr('value', JSON.stringify(materials)));
        form.appendTo('body').submit().remove();
    }

    function getShoppingListForWeek(shoppingListCallback) {
        const chef = shoppingListView.attr('data-chef');
        const today = new Date();
        const one_week = new Date();
        one_week.setDate(one_week.getDate() + 7);

        $('#shopping-list-header').animate({'opacity': 0}, 400, function () {
            $(this).html(`${today.toLocaleDateString()} - ${one_week.toLocaleDateString()}`).animate({'opacity': 1}, 400);
        });

        $.get(`/chefs/${chef}/shopping_list/${today}/${one_week}`, function (response) {
            shoppingListCallback(response);
        }, 'json');
    }

    const shoppingListView = $('#shopping-list-view');
    if (shoppingListView.length) {
        getShoppingListForWeek(function (response) {
            if (response.ingredients.length > 0) {
                let ingredientsHTML = '';
                response.ingredients.forEach(function (ingredient) {
                    const vendor = ingredient.vendor ? ` (${ingredient.vendor})` : ''
                    const name = `${ingredient.name} ${vendor}`
                    const data = `${ingredient.quantity}&nbsp;${ingredient.measurement}&nbsp;(${units(ingredient.quantity, ingredient.measurement, ingredient.unit_measurement)}&nbsp;${ingredient.unit_measurement})`

                    // FIXME: would be nice to render the link_data_row partial, but we have javascript variables we need due to the need to convert units
                    ingredientsHTML +=
                        `<section class='row data-row week-shopping-list-ingredient-row pt-2 pb-2' data-recipe="${ingredient.recipe}" data-name="${ingredient.name}" data-quantity="${ingredient.quantity}" data-measurement="${ingredient.measurement}" data-unit-measurement="${ingredient.unit_measurement}">` +
                        `<div class='col-lg-4 col-6'><span style='text-decoration: underline dotted; text-underline-position: under;'><a href='/ingredients/${ingredient.id}' data-turbolinks='false'>${name}</a></span></div>` +
                        `<div class='col-lg-8 col-6 text-right text-truncate' style='cursor: inherit;'><strong>${data}</strong></div>` +
                        '</section>'
                });

                $('#no-shopping-items').hide();
                $('#shopping-items').html(ingredientsHTML);
                $('#date-range-print-shopping-list-button').show();
            } else {
                $('#shopping-items').hide();
                $('#date-range-print-shopping-list-button').hide();
                $('#no-shopping-items').show();
            }
        });
    }

    $('#date-range-print-shopping-list-button').click(function () {
        const chef = shoppingListView.attr('data-chef');
        const today = new Date();
        const one_week = new Date();
        one_week.setDate(one_week.getDate() + 7);

        let ingredients = []
        $('.week-shopping-list-ingredient-row').each(function () {
            const ingredient = $(this);
            ingredients.push({
                recipe: ingredient.data('recipe'),
                name: ingredient.data('name'),
                quantity: `${ingredient.data('quantity')} ${ingredient.data('measurement')} (${units($(this).data('quantity'), $(this).data('measurement'), $(this).data('unit-measurement'))} ${ingredient.data('unit-measurement')})`,
            });
        });

        // let materials = []
        // $('.menu-item-material-row').each(function () {
        //     const material = $(this);
        //     materials.push({
        //         recipe: material.data('recipe'),
        //         name: material.data('name'),
        //         quantity: material.data('quantity')
        //     });
        // });

        let form = $('<form></form>').attr('action', `/chefs/${chef}/print_shopping_list/${today}/${one_week}`).attr('method', 'post');
        form.append($("<input>").attr('type', 'hidden').attr('name', 'ingredients').attr('value', JSON.stringify(ingredients)));
        // form.append($("<input>").attr('type', 'hidden').attr('name', 'materials').attr('value', JSON.stringify(materials)));
        form.appendTo('body').submit().remove();
    });
});
