$(document).on("turbolinks:load", function () {
    "use strict";

    $('#delivery-estimate-button').on('click', function (e) {
        e.preventDefault();

        const address = $('#address1').val();
        const city = $('#city').val();
        const state = $('#state').val();
        const zip = $('#postal-code').val();
        const date = $('#fulfillment_date').val();

        let time = $('#fulfillment_time_delivery');
        if (time.length) {
            time = time.val();
        } else {
            time = $('#fulfillment_time').val();
        }

        if (address && city && state && zip && date && time) {
            const loadingModal = $('#loading-modal');
            if (loadingModal.length) {
                loadingModal.modal({backdrop: 'static', keyboard: false});
            }

            $('#courier-delivery-error-message').html('');
            $('#formatted-delivery-address').html(`<div>${address}</div><div>${city}, ${state} ${zip}</div>`);

            let url = '';
            if ($('#new-order-wizard').length) {
                url = '/delivery/chef_estimate';
            } else {
                url = '/delivery/shop_estimate';
            }

            $.post(url, {
                delivery_address: address,
                delivery_city: city,
                delivery_state: state,
                delivery_zip: zip,
                fulfillment_date: date,
                fulfillment_time: time
            }, function (response) {
                if (loadingModal) {
                    loadingModal.removeClass("in");
                    $('.modal-backdrop').remove();
                    $('body').removeClass('modal-open').css('padding-right', '');
                    loadingModal.hide();
                }
                if (response.message) {
                    $('#courier-delivery-error-message').hide().html(`<strong>Error:</strong> ${response.message}`).fadeIn('slow');
                } else {
                    const deliveryEstimateModal = $('#delivery-estimate-modal');
                    const deliveryEstimateModalSimple = $('#delivery-estimate-modal-simple');

                    if (deliveryEstimateModal.length) {
                        deliveryEstimateModal.modal({backdrop: 'static', keyboard: false});
                    }

                    if (deliveryEstimateModalSimple.length) {
                        deliveryEstimateModalSimple.modal();
                    }

                    $('#delivery-estimate-modal-delivery-charge').html(`<strong>$${response.estimated_delivery_charge.toFixed(2)}</strong>`)
                    $('#delivery-estimate-modal-total').html(`<strong>$${response.total.toFixed(2)}</strong>`)

                    const newChefOrderDeliveryCharge = $('#courier-delivery-charge-order-info-data')
                    if (newChefOrderDeliveryCharge.length) {
                        newChefOrderDeliveryCharge.html(`$${response.estimated_delivery_charge.toFixed(2)}`);
                    }

                    const newChefOrderServiceCharge = $('#new-chef-order-service-charge');
                    if (newChefOrderServiceCharge.length) {
                        newChefOrderServiceCharge.html(`$${response.service_charge.toFixed(2)}`);
                    }

                    const newChefOrderTotal = $('#new-chef-order-total');
                    if (newChefOrderTotal.length) {
                        newChefOrderTotal.html(`$${response.total.toFixed(2)}`)
                    }

                    const newChefOrderSaveButton = $('#save-order-button');
                    if (newChefOrderSaveButton.length) {
                        newChefOrderSaveButton.removeClass('disabled');
                    }
                }
            }, 'json')
        } else {
            $('#courier-delivery-error-message').hide().html('<strong>Error:</strong> Please ensure that all delivery fields are supplied including delivery date and address above.').fadeIn('slow');
        }
    });

    $('#change-delivery-details-button').on('click', function () {
        $('#delivery-estimate-modal').modal('hide');
    });
});