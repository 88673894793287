$(document).on("turbolinks:load", function () {
    "use strict";

    $('.searchable-input').keyup(function() {
        if (this.value.length < 2) {
            $('.searchable-row').show();
        } else {
            $('.searchable-row').hide();
            $(`.searchable-row[data-searchable*='${this.value.toLowerCase()}']`).show();
        }
    });
});