$(document).on('turbolinks:load', function () {
    $("#text-to-copy-button").on('click', function (e) {
        copyText();
    });

    window.copyText = function () {
        const modal = $('#text-copied-modal');
        const text = document.getElementById('text-to-copy').textContent;
        const copied = copy(text,
            {
                format: 'text/plain',
                onCopy: function (e) {
                    $('#text-copied-text').html(`<strong>Text copied: </strong> ${text}`);
                    modal.modal('show');
                    setTimeout(function () {
                        $('#text-copied-text').html('');
                        modal.modal('hide');
                    }, 1500);
                }
            }
        );

        if (!copied) {
            console.log("Error: Unable to copy text.  Please report this error to https://indiechef.io/support.")
        }
    }
});