$(document).on("turbolinks:load", function () {
    "use strict";

    const topSellers = $("#top-sellers");
    if (topSellers.length) {
        if (topSellers.length) {
            new Chart(document.getElementById("top-sellers"), {
                responsive: "true",
                type: "pie",
                data: {
                    labels: topSellers.data("top-seller-names"),
                    datasets: [
                        {
                            label: "Top Sellers",
                            data: topSellers.data("top-seller-numbers"),
                            backgroundColor: [
                                "rgb(0, 128, 0)",
                                "rgb(0, 112, 224)",
                                "rgb(216, 0, 115)",
                                "rgb(250, 104, 0)",
                                "rgb(170, 0, 250)",
                                "rgb(241, 194, 5)"
                            ]
                        }
                    ]
                }
            });
        }
    }

    const monthEarnings = $("#month-earnings");
    if (monthEarnings.length) {
        new Chart(document.getElementById("month-earnings"), {
            "type": "line",
            "data": {
                "labels": monthEarnings.data("month-names"),
                "datasets": [{
                    "label": "Earnings / Month",
                    "data": monthEarnings.data("earnings-by-month"),
                    "fill": true,
                    "borderColor": "rgb(99, 203, 137)",
                    "lineTension": 0.1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            stepSize: 50,
                            suggestedMin: 0
                        }
                    }]
                }
            }
        });
    }
});