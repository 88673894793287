global.FullCalendar = require("@fullcalendar/core/");

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

$(document).on("turbolinks:load", function () {
    "use strict";

    $('.timepicker').timepicker({
        timeFormat: 'h:mm p',
        interval: 15,
        dynamic: false,
        dropdown: true,
        scrollbar: true
    });

    if ($('#chef-dashboard').length) {
        const chefId = $('#chef-dashboard').attr('data-chef');

        let events = []

        $.get(`/chefs/${chefId}/calendar_events`, function (response) {
            events = response.events;

            const calendars = [document.getElementById('calendar-mobile'), document.getElementById('calendar-desktop')];
            calendars.forEach(function (calendarEl) {
                const calendar = new FullCalendar.Calendar(calendarEl, {
                    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
                    initialView: 'dayGridWeek',
                    height: 314,
                    defaultAllDay: true,
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridWeek,dayGridMonth,timeGridDay,listWeek',
                    },
                    aspectRatio: 3,
                    eventClick: function (info) {
                        info.jsEvent.preventDefault();
                        window.location = info.event.url;
                    },
                    events: events
                });
                calendar.render();
            });
        }, 'json');
    }
});