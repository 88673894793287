const {introJs} = require('intro.js/intro');

$(document).on('turbolinks:load', function () {
    "use strict";

    if ($('#tour').length) {
        showTour();
    }

    document.querySelectorAll('[data-tour]').forEach(function (tour_button) {
        tour_button.addEventListener('click', function (event) {
            $('#show-tour-modal').modal('hide');
            showTour(false);
        });
    });

    function showTour(doCallbacks = true) {
        const chefSlug = $('#tour').attr('data-chef');
        introJs()
            .setOption('showProgress', true)
            .setOption('showStepNumbers', false)
            .setOption('exitOnEsc', false)
            .setOption('exitOnOverlayClick', false)
            .oncomplete(function () {
                if (doCallbacks) {
                    $.post(`/chefs/${chefSlug}/end_tour`, {status: 'complete'}, function (response) {
                        // handle
                    }, 'json');
                }
            })
            .onexit(function () {
                if (doCallbacks) {
                    $.post(`/chefs/${chefSlug}/end_tour`, {status: 'skip'}, function (response) {
                        // handle
                    }, 'json');
                }
            })
            .start();
    }
});