$(document).on("turbolinks:load", function () {
    "use strict";

    $.urlParam = function (name) {
        const results = new RegExp('[\?&]' + name + '=([^&#]*)')
            .exec(window.location.search);

        return (results !== null) ? results[1] || 0 : false;
    }

    if ($.urlParam('genie') === 'true') {
        let driver = new Driver({
            allowClose: true,
            animate: false,
            showButtons: false
        });

        let title;
        let description;
        let position = 'bottom'

        if (window.location.pathname === '/menu_items') {
            title  = 'Click here to create a new Menu Item'
            description = 'Enter Menu Item name, price, and upload an image then click save.'
        }

        if (window.location.pathname === '/invoices') {
            title  = 'Click here to create a new Invoice'
            description = 'Enter customer information, provide invoice details, then click save and/or send.'
        }

        if (window.location.pathname === '/bookings') {
            title  = 'Click here to create a new Booking'
            description = 'Enter customer information, provide booking details, then click create booking.'
        }

        if (window.location.pathname === '/dashboard/menu/fulfillment') {
            title  = 'Courier Delivery'
            description = 'Click here to turn on Courier Delivery. Enter delivery details then click save. Remember to update your Hours of Operation and Calendar above!'
            position = 'top'
        }

        if (window.location.pathname === '/dashboard') {
            title  = 'Click here to go create a new order'
            description = 'Add Menu items to an order then send your customer the secure payment link.'
        }

        if (window.location.pathname === '/orders') {
            title  = 'View Orders here'
            description = ''
            position = 'top'
        }

        driver.highlight({
            element: '.genie',
            popover: {
                title: title,
                description: description,
                position: position
            }
        });

        $('.genie').on({
            click: function () {
                driver.reset(true);
            }
        });

        driver.highlight({
            element: '.genie-mobile',
            popover: {
                title: title,
                description: description
            }
        });

        $('.genie-mobile').on({
            click: function () {
                driver.reset(true);
            }
        });

        window.setTimeout(() => {
            driver.refresh();
        }, 500)
    }

    $("#genie-menu").on({
        click: function () {
            window.location.href = '/menu_items?genie=true';
        }
    });

    $("#genie-invoice").on({
        click: function () {
            window.location.href = '/invoices?genie=true';
        }
    });

    $("#genie-booking").on({
        click: function () {
            window.location.href = '/bookings?genie=true';
        }
    });

    $("#genie-courier-delivery").on({
        click: function () {
            window.location.href = '/dashboard/menu/fulfillment?genie=true';
        }
    });

    $("#genie-popup").on({
        click: function () {
            window.location.href = '/dashboard?genie=true';
        }
    });

    $("#genie-orders").on({
        click: function () {
            window.location.href = '/orders?genie=true';
        }
    });
});