$(document).on("turbolinks:load", function () {
    "use strict";

    $(document).on("keydown", "#new-chef-wizard-form", function (event) {
        return event.key !== "Enter";
    });

    $('#chef_business_name').keyup(function () {
        const $identifier = $(this).val().toLowerCase().replace(/\s/g, '_').replace(/\W/g, '');
        $('#chef-url').html('https://indiechef.io/menu/' + $identifier);
        $('#chef_slug').val($identifier);
    });

    const $validator = $("#new-chef-wizard-form").validate();

    $('#new-chef-wizard').bootstrapWizard({
        'tabClass': 'nav nav-tabs',
        onTabShow: function (tab, navigation, index) {
            const $total = navigation.find('li').length;
            const $current = index + 1;
            const $percent = ($current / $total) * 100;
            $('#new-chef-wizard').find('.progress-bar').css({width: $percent + '%'});
        },
        onNext: function (tab, navigation, index) {
            return onNextTab(tab, navigation, index);
        },
        onTabClick: function (tab, navigation, index) {
            return onNextTab(tab, navigation, index);
        }
    });

    function onNextTab(tab, navigation, index) {
        if (!$('#new-chef-wizard-form').valid()) {
            $validator.focusInvalid();
            return false;
        }

        if (index === 3) {
            $('#new-chef-next-button').html('Finish');
        } else {
            if (index === 4) {
                $('#demo-items-modal').modal();
            } else {
                $('#new-chef-next-button').html('Next');
            }
        }
    }

    $('#new-chef-next-button, #new-chef-previous-button').click(function (e) {
        e.preventDefault();
    });

    $("#chef_logo").change(function () {
        const input = this;
        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = function (e) {
                $('#chef-logo').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    });

    $('#demo-data-dashboard').click(function () {
        const input = $("<input>").attr('type', 'hidden').attr('name', 'demo').val("true");
        $('#new-chef-wizard-form').append($(input)).submit();
    })

    $('#no-demo-data-dashboard').click(function () {
        $('#new-chef-wizard-form').submit();
    })
});