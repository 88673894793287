$(document).on("turbolinks:load", function () {
    "use strict";

    $('#new-order-wizard').bootstrapWizard({
        'tabClass': 'nav nav-tabs',
        onTabShow: function (tab, navigation, index) {
            const $total = navigation.find('li').length;
            const $current = index + 1;
            const $percent = ($current / $total) * 100;
            $('#new-order-wizard').find('.progress-bar').css({width: $percent + '%'});
            return onTab(tab, navigation, index)
        },
        onPrevious: function (tab, navigation, index) {
            return onTab(tab, navigation, index);
        },
        onNext: function (tab, navigation, index) {
            return onTab(tab, navigation, index);
        },
        onTabClick: function (tab, navigation, index) {
            return onTab(tab, navigation, index);
        }
    });

    function onTab(tab, navigation, index) {
        if ($('#new-order-wizard-data').data('fulfillmentAllowed') === true) {
            if (index === 0) {
                $('#new-order-back-button').addClass('d-none');
                $('#popup-order-button').removeClass('d-none');
                $('#new-order-next-button').removeClass('d-none');
            } else if (index === 1) {
                $('#popup-order-button').addClass('d-none');
                $('#new-order-back-button').removeClass('d-none');
                $('#new-order-next-button').addClass('d-none');
            }
        } else {
            if (index > 0) {
                $('#no-menu-items-modal').modal();
                return false;
            }
        }
    }

    $('#new-order-next-button, #new-order-previous-button').on('click', function (e) {
        e.preventDefault();
    });

    $('#save-and-send-pickup-order-button, #save-and-send-delivery-order-button').on('click', function (e) {
        e.preventDefault();
        $('<input>', {
            type: 'hidden',
            id: 'send_to_customer',
            name: 'send_to_customer',
            value: 'true'
        }).appendTo('form');
        triggerOrderForm();
    });

    $('#save-pickup-order-button, #save-delivery-order-button').on('click', function (e) {
        e.preventDefault();
        $('<input>', {
            type: 'hidden',
            id: 'send_to_customer',
            name: 'send_to_customer',
            value: 'false'
        }).appendTo('form');
        triggerOrderForm();
    });

    function triggerOrderForm() {
        const form = $('form').last();
        form.validate();
        form.trigger('submit');
    }

    $('#fulfillment-pickup-button').on('click', function (e) {
        $(this).addClass('active btn-primary');
        $('#fulfillment-delivery-button').removeClass('active btn-primary');
        $('#pickup-form').removeClass('d-none');
        $('#delivery-form').addClass('d-none');
        $('#pickup-fulfillment-type-order-info').removeClass('d-none');
        $('#delivery-fulfillment-type-order-info').addClass('d-none');
        $('#courier-delivery-charge-order-info').addClass('d-none');
        $('#chef-delivery-charge-order-info').addClass('d-none');

        const subtotal = Number($('#new-chef-order-subtotal').html().replace(/[^0-9.-]+/g,""));
        const serviceCharge = Number($('#new-chef-order-service-charge').html().replace(/[^0-9.-]+/g,""));
        $('#new-chef-order-total').html('<strong>$' + numberWithCommas((subtotal + serviceCharge).toFixed(2)) + '</strong>');

        $('<input>', {
            type: 'hidden',
            id: 'chef_order_pickup',
            name: 'chef_order[pickup]',
            value: 'true'
        }).appendTo('form');

        $('<input>', {
            type: 'hidden',
            id: 'chef_order_delivery',
            name: 'chef_order[delivery]',
            value: 'false'
        }).appendTo('form');

        $('#save-order-button').removeClass('disabled');
    });

    $('#fulfillment-delivery-button').on('click', function (e) {
        $(this).addClass('active btn-primary');
        $('#fulfillment-pickup-button').removeClass('active btn-primary');
        $('#pickup-form').addClass('d-none');
        $('#delivery-form').removeClass('d-none');
        $('#delivery-fulfillment-type-order-info').removeClass('d-none');
        $('#pickup-fulfillment-type-order-info').addClass('d-none');

        if ($('#new-order-wizard-data').data('courierDeliveryEnabled') === true) {
            $('#courier-delivery-charge-order-info').removeClass('d-none');
            $('#chef-delivery-charge-order-info').addClass('d-none');
            $('#save-order-button').addClass('disabled');
        } else if ($('#new-order-wizard-data').data('chefDeliveryEnabled') === true) {
            $('#chef-delivery-charge-order-info').removeClass('d-none');
            $('#courier-delivery-charge-order-info').addClass('d-none');
            $('#delivery-estimate-button').addClass('d-none');
        }

        $('<input>', {
            type: 'hidden',
            id: 'chef_order_delivery',
            name: 'chef_order[delivery]',
            value: 'true'
        }).appendTo('form');

        $('<input>', {
            type: 'hidden',
            id: 'chef_order_pickup',
            name: 'chef_order[pickup]',
            value: 'false'
        }).appendTo('form');
    });
});