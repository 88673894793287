$(document).on("turbolinks:load", function () {
    const homeScreenModal = $('#home-screen-modal');
    if (homeScreenModal.length > 0) {
        const userAgent = navigator.userAgent;

        let safariAgent = userAgent.indexOf("Safari") > -1;
        let chromeAgent = userAgent.indexOf("Chrome") > -1;

        if ((chromeAgent) && (safariAgent))
            safariAgent = false;

        const screenWidth = $(window).width();

        let homeScreenModalShownTimes = localStorage.getItem("homeScreenModalShownTimes");
        const targetWidth = 768;
        if (safariAgent && screenWidth < targetWidth && homeScreenModalShownTimes !== "3") {
            if (homeScreenModalShownTimes === "2") {
                localStorage.setItem("homeScreenModalShownTimes", "3");
            } else if (homeScreenModalShownTimes === "1") {
                localStorage.setItem("homeScreenModalShownTimes", "2");
            } else {
                localStorage.setItem("homeScreenModalShownTimes", "1");
            }

            homeScreenModal.modal();
        }
    }
});