$(document).on("turbolinks:load", function () {
    $('.text-truncate').each(function () {
        const truncateElement = $(this);

        // FIXME: this should only show expandable when ellipsis is shown, but it's always showing
        // if (truncateElement.outerWidth() < truncateElement[0].scrollWidth) {
        //     truncateElement.addClass('expandable');
        // }

        truncateElement.click(function () {
            $(this).removeClass('text-truncate expandable');
        });
    })
});
