$(document).on("turbolinks:load", function () {
    "use strict";

    let dragCategory;
    let menuItemsTable = $('#menu-items');
    let categoriesTable = $('#categories');

    if (menuItemsTable.length) {
        menuItemsTable.tableDnD({
            onDragStart: function (table, row) {
                dragCategory = $(row).data('category');
            },
            onAllowDrop: function (table, row) {
                // prevent drop if categories don't match
                return dragCategory === $(row).data('category');
            },
            onDrop: function (table, row) {
                const rows = table.tBodies[0].rows;
                let order = [];
                for (let i = 0; i < rows.length; i++) {
                    order.push(rows[i].id);
                }

                $.post('/menu_items/reorder', {order: order}, function (response) {
                    dragCategory = null;
                }, 'json');
            }
        });
    }

    if (categoriesTable.length) {
        categoriesTable.tableDnD({
            onDrop: function (table, row) {
                const rows = table.tBodies[0].rows;
                let order = [];
                for (let i = 0; i < rows.length; i++) {
                    order.push(rows[i].id);
                }

                $.post('/categories/reorder', {order: order}, function (response) {
                }, 'json');
            }
        });
    }
});