window.map = null;
window.currentLocationMarker = {};

$(document).on("turbolinks:load", function () {
    "use strict";

    window.initMap = function () {

        const mapElement = document.getElementById("map");

        const deliveryLocation = {
            lat: parseFloat(mapElement.dataset.deliveryLatitude),
            lng: parseFloat(mapElement.dataset.deliveryLongitude)
        };
        let bounds = new google.maps.LatLngBounds();

        map = new google.maps.Map(mapElement, {
            center: deliveryLocation,
            zoom: 8,
        });

        const deliveryMarker = new google.maps.Marker({
            position: deliveryLocation,
            map
        });
        bounds.extend(deliveryMarker.position);

        const driverIcon = {
            path: 'M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z',
            fillColor: '#000',
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 0.05,
            anchor: new google.maps.Point(15, 30)
        }

        const driverMarker = new google.maps.Marker({
            position: {lat: parseFloat(mapElement.dataset.driverLatitude), lng: parseFloat(mapElement.dataset.driverLongitude)},
            icon: driverIcon,
            map
        });
        bounds.extend(driverMarker.position);
    }
});